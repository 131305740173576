import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 0,
  pageTitle:"Home"
}

export const common = createSlice({
  name: 'common',
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1
    },
    decrement: (state) => {
      state.value -= 1
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload
    },
    setPageTitle:(state, action)=>{
       state.pageTitle=action.payload 
    }
  },
})

export const { increment, decrement, incrementByAmount, setPageTitle } = common.actions

export default common.reducer