import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import { ChakraProvider, Spinner } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import reportWebVitals from "./reportWebVitals";
import ReduxProvider from "./Providers/ReduxProvider";

const App = lazy(() => import("./App"));

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ReduxProvider>
    <ChakraProvider resetCSS>
      <BrowserRouter>
        <Suspense fallback={<Spinner as="progress" />}>
          <GoogleOAuthProvider clientId="135738513049-7kc19hlt3d26bnt3pvijb1nc6d0n9ne7.apps.googleusercontent.com">
            <App />
          </GoogleOAuthProvider>
        </Suspense>
      </BrowserRouter>
    </ChakraProvider>
  </ReduxProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
